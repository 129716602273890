import { useEffect } from "react";
import Router from "next/router";
import Head from "next/head";
import { useAnalytics } from "use-analytics";

import { Grid, Link, Typography } from "@/ui";
import Layout from "@/components/MyLayout";

import { useStyles } from "../styles/error";
import { isProdEnv } from "@/config";

const Error = ({ classes: propClasses = {}, statusCode = 500, error }) => {
  const classes = useStyles({ classes: propClasses });

  const legend =
    statusCode === 404
      ? "Oops! We couldn’t find this page."
      : "Oops! Something went wrong!";

  // analytics page tracking
  const { page } = useAnalytics();
  useEffect(() => {
    page({
      category: "errors",
      pageType: "error",
      errorCode: statusCode,
      name: "Error Page",
    });
  }, [statusCode, page]);

  useEffect(() => {
    if (!isProdEnv) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [error]);

  return (
    <>
      <Head>
        <title key="title">Error Page | AtlasGuru</title>
      </Head>
      <Layout
        classes={{
          layout: classes.root,
          mainContainer: classes.mainContainer,
        }}
      >
        <div className="container">
          <Grid className={classes.content} container spacing={3}>
            <Grid item xs={12} className={classes.row}>
              <Typography variant="h1" component="p" className={classes.header}>
                {statusCode}
              </Typography>
              <Typography variant="h2" component="strong">
                {legend}
              </Typography>
              <Typography
                variant="body1"
                component="strong"
                className={classes.footer}
              >
                Try Search or <Link onClick={() => Router.back()}>Go Back</Link>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </>
  );
};

Error.getInitialProps = async ({ res, err }) => {
  const { statusCode } = res || err;
  return { statusCode, error: err };
};

export default Error;
