import { makeStyles } from "@mui/styles";

import { staticUrl } from "@/lib/utils";

const bgImageMobile = staticUrl(
  require("../static/img/theme-bg-jungle-mobile.jpg"),
);

const bgImageDesktop = staticUrl(
  require("../static/img/theme-bg-jungle-desktop.jpg"),
);

const styles = (theme) => ({
  mainContainer: {
    background: "no-repeat 50% 70%",
    backgroundSize: "cover",
    backgroundImage: `url(${bgImageMobile})`,

    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${bgImageDesktop})`,
    },

    "&:before": {
      content: "none",
    },
  },

  content: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    minHeight: "60vh",
    textAlign: "center",

    "& a": {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
    },

    "& strong": {
      color: theme.palette.common.white,
    },
  },

  header: {
    ...theme.typography.jumbo,
    color: theme.palette.error.main,
  },

  row: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
  },

  footer: {
    margin: "1em 0 2em",
  },
});

export const useStyles = makeStyles(styles);

export default styles;
